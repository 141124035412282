<template>
  <div id="app-base-file">
      <Message  :uid="userid" :chatType="type" @sendM="sendM" @updateInfos="updateInfos"></Message>
  </div>
</template>
<script>
import Message  from '@/components/Message.vue'
export default {
  props: {
    uid: {
      type: String,
      default: ''
    },
    chatType:{
      type: String,
      default: ''
    }
  },
  components:{
    Message
  },
  data() {
    return {
      userid:'',
      type:''
    };
  },
  watch: {
    uid: function (e) {
      this.userid = this.uid
    },
    chatType: function (e) {
      this.type = this.chatType
    },
  },
  mounted () {
    this.userid = this.uid
    this.type = this.chatType
  },
  methods: {
    updateInfos(e){
      this.$emit('updateInfos',e)
    },
    sendM(e){
      this.$emit('sendM',e)
    }
  }
};
</script> 
<style lang="less" scoped>
#app-base-file {
  width: 100%;
  background-color: #fff;
  height: 100%;
}
</style>
